<template>
    <ul class="details">
      <li v-for="g in list" :key="g.id">
        <img :src="g.mainImg" alt="" />
        <h3>{{ g.title }}</h3>
      </li>
    </ul>
</template>

<script>
export default {
  name: "Clothes",
  data () {
      return {
          list: this.$store.state.productList[3].goods
      }
  }
};
</script>